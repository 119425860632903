import { default as _404VP3H4oMKLaMeta } from "/root/injective/injective-explorer/pages/404.vue?macro=true";
import { default as cw20balances3scklCrppyMeta } from "/root/injective/injective-explorer/pages/account/[account]/cw20balances.vue?macro=true";
import { default as indexEtKApAjMUFMeta } from "/root/injective/injective-explorer/pages/account/[account]/index.vue?macro=true";
import { default as indexASn0H6GWapMeta } from "/root/injective/injective-explorer/pages/account/[account]/staking/index.vue?macro=true";
import { default as redelegationsi4Y8e8HJ24Meta } from "/root/injective/injective-explorer/pages/account/[account]/staking/redelegations.vue?macro=true";
import { default as unbondingsLra5BidAnaMeta } from "/root/injective/injective-explorer/pages/account/[account]/staking/unbondings.vue?macro=true";
import { default as stakingM7WPntzGg2Meta } from "/root/injective/injective-explorer/pages/account/[account]/staking.vue?macro=true";
import { default as transactionsC1GKJEVodgMeta } from "/root/injective/injective-explorer/pages/account/[account]/transactions.vue?macro=true";
import { default as _91account_93DGLYNxvW9qMeta } from "/root/injective/injective-explorer/pages/account/[account].vue?macro=true";
import { default as asset5fnK4ofv1ZMeta } from "/root/injective/injective-explorer/pages/asset.vue?macro=true";
import { default as indexTmsuElRnfNMeta } from "/root/injective/injective-explorer/pages/assets/index.vue?macro=true";
import { default as smart_45contracts4VeFRZarQmMeta } from "/root/injective/injective-explorer/pages/assets/smart-contracts.vue?macro=true";
import { default as assetsHcZHB6i7BVMeta } from "/root/injective/injective-explorer/pages/assets.vue?macro=true";
import { default as _91block_93xUC4khrIXxMeta } from "/root/injective/injective-explorer/pages/block/[block].vue?macro=true";
import { default as blocksegUtDtgV3CMeta } from "/root/injective/injective-explorer/pages/blocks.vue?macro=true";
import { default as _91code_93Q2BY7vEWapMeta } from "/root/injective/injective-explorer/pages/code/[code].vue?macro=true";
import { default as codesGfAJNlJycQMeta } from "/root/injective/injective-explorer/pages/codes.vue?macro=true";
import { default as community_45spend_45poolWGL8qqFK5xMeta } from "/root/injective/injective-explorer/pages/community-spend-pool.vue?macro=true";
import { default as balances7a9hXHtGjOMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/balances.vue?macro=true";
import { default as cw20balancesi6ePunGBeYMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/cw20balances.vue?macro=true";
import { default as indexZGpec3rzKQMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/index.vue?macro=true";
import { default as init8Xmve2jK5bMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/init.vue?macro=true";
import { default as token_45holdersFIsWfKzYFmMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/token-holders.vue?macro=true";
import { default as _91contract_93GlV318eHqYMeta } from "/root/injective/injective-explorer/pages/contract/[contract].vue?macro=true";
import { default as contracts1RUTeIvKxlMeta } from "/root/injective/injective-explorer/pages/contracts.vue?macro=true";
import { default as envfVxRgKM8eSMeta } from "/root/injective/injective-explorer/pages/env.vue?macro=true";
import { default as featured_45dappsWBPMTNaNCVMeta } from "/root/injective/injective-explorer/pages/featured-dapps.vue?macro=true";
import { default as indexWZOyDGGrETMeta } from "/root/injective/injective-explorer/pages/index.vue?macro=true";
import { default as maintenancepyNbpbFYruMeta } from "/root/injective/injective-explorer/pages/maintenance.vue?macro=true";
import { default as funding_45rate_45historyTGshQH2inOMeta } from "/root/injective/injective-explorer/pages/markets/funding-rate-history.vue?macro=true";
import { default as indexmMfb5AoAc5Meta } from "/root/injective/injective-explorer/pages/markets/index.vue?macro=true";
import { default as realtime_45funding_45rateumdgJ3vpsXMeta } from "/root/injective/injective-explorer/pages/markets/realtime-funding-rate.vue?macro=true";
import { default as _91marketId_93rn5xB5gsIkMeta } from "/root/injective/injective-explorer/pages/markets/spot/[marketId].vue?macro=true";
import { default as trading_45rulesX6fKsWlUhiMeta } from "/root/injective/injective-explorer/pages/markets/trading-rules.vue?macro=true";
import { default as marketsVfkNwOYM9KMeta } from "/root/injective/injective-explorer/pages/markets.vue?macro=true";
import { default as raw_45txYYZOTj6lA5Meta } from "/root/injective/injective-explorer/pages/raw-tx.vue?macro=true";
import { default as _91tradeId_93PPpdtTkqjjMeta } from "/root/injective/injective-explorer/pages/trade-history/derivatives/[tradeId].vue?macro=true";
import { default as indexYJ8GcAEGLGMeta } from "/root/injective/injective-explorer/pages/trade-history/derivatives/index.vue?macro=true";
import { default as indexoVQXdDDGgUMeta } from "/root/injective/injective-explorer/pages/trade-history/index.vue?macro=true";
import { default as _91tradeId_93ovBqdHLNdVMeta } from "/root/injective/injective-explorer/pages/trade-history/spot/[tradeId].vue?macro=true";
import { default as indexPrDLL3R2lqMeta } from "/root/injective/injective-explorer/pages/trade-history/spot/index.vue?macro=true";
import { default as trade_45history4wdxWrri2GMeta } from "/root/injective/injective-explorer/pages/trade-history.vue?macro=true";
import { default as event_45logsDp3qPiJPVyMeta } from "/root/injective/injective-explorer/pages/transaction/[transaction]/event-logs.vue?macro=true";
import { default as indexWJ8edPC8EWMeta } from "/root/injective/injective-explorer/pages/transaction/[transaction]/index.vue?macro=true";
import { default as _91transaction_93O7jI16oisQMeta } from "/root/injective/injective-explorer/pages/transaction/[transaction].vue?macro=true";
import { default as transactionsc0W8ckAwY1Meta } from "/root/injective/injective-explorer/pages/transactions.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/root/injective/injective-explorer/pages/404.vue")
  },
  {
    name: _91account_93DGLYNxvW9qMeta?.name,
    path: "/account/:account()",
    meta: _91account_93DGLYNxvW9qMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/account/[account].vue"),
    children: [
  {
    name: "account-account-cw20balances",
    path: "cw20balances",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/cw20balances.vue")
  },
  {
    name: "account-account",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/index.vue")
  },
  {
    name: stakingM7WPntzGg2Meta?.name,
    path: "staking",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking.vue"),
    children: [
  {
    name: "account-account-staking",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking/index.vue")
  },
  {
    name: "account-account-staking-redelegations",
    path: "redelegations",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking/redelegations.vue")
  },
  {
    name: "account-account-staking-unbondings",
    path: "unbondings",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking/unbondings.vue")
  }
]
  },
  {
    name: "account-account-transactions",
    path: "transactions",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/transactions.vue")
  }
]
  },
  {
    name: "asset",
    path: "/asset",
    component: () => import("/root/injective/injective-explorer/pages/asset.vue")
  },
  {
    name: assetsHcZHB6i7BVMeta?.name,
    path: "/assets",
    component: () => import("/root/injective/injective-explorer/pages/assets.vue"),
    children: [
  {
    name: "assets",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/assets/index.vue")
  },
  {
    name: "assets-smart-contracts",
    path: "smart-contracts",
    component: () => import("/root/injective/injective-explorer/pages/assets/smart-contracts.vue")
  }
]
  },
  {
    name: "block-block",
    path: "/block/:block()",
    meta: _91block_93xUC4khrIXxMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/block/[block].vue")
  },
  {
    name: "blocks",
    path: "/blocks",
    component: () => import("/root/injective/injective-explorer/pages/blocks.vue")
  },
  {
    name: "code-code",
    path: "/code/:code()",
    meta: _91code_93Q2BY7vEWapMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/code/[code].vue")
  },
  {
    name: "codes",
    path: "/codes",
    component: () => import("/root/injective/injective-explorer/pages/codes.vue")
  },
  {
    name: "community-spend-pool",
    path: "/community-spend-pool",
    component: () => import("/root/injective/injective-explorer/pages/community-spend-pool.vue")
  },
  {
    name: _91contract_93GlV318eHqYMeta?.name,
    path: "/contract/:contract()",
    meta: _91contract_93GlV318eHqYMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract].vue"),
    children: [
  {
    name: "contract-contract-balances",
    path: "balances",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/balances.vue")
  },
  {
    name: "contract-contract-cw20balances",
    path: "cw20balances",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/cw20balances.vue")
  },
  {
    name: "contract-contract",
    path: "",
    meta: indexZGpec3rzKQMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/index.vue")
  },
  {
    name: "contract-contract-init",
    path: "init",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/init.vue")
  },
  {
    name: "contract-contract-token-holders",
    path: "token-holders",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/token-holders.vue")
  }
]
  },
  {
    name: "contracts",
    path: "/contracts",
    component: () => import("/root/injective/injective-explorer/pages/contracts.vue")
  },
  {
    name: "env",
    path: "/env",
    meta: envfVxRgKM8eSMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/env.vue")
  },
  {
    name: "featured-dapps",
    path: "/featured-dapps",
    component: () => import("/root/injective/injective-explorer/pages/featured-dapps.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/injective/injective-explorer/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancepyNbpbFYruMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/maintenance.vue")
  },
  {
    name: marketsVfkNwOYM9KMeta?.name,
    path: "/markets",
    component: () => import("/root/injective/injective-explorer/pages/markets.vue"),
    children: [
  {
    name: "markets-funding-rate-history",
    path: "funding-rate-history",
    component: () => import("/root/injective/injective-explorer/pages/markets/funding-rate-history.vue")
  },
  {
    name: "markets",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/markets/index.vue")
  },
  {
    name: "markets-realtime-funding-rate",
    path: "realtime-funding-rate",
    component: () => import("/root/injective/injective-explorer/pages/markets/realtime-funding-rate.vue")
  },
  {
    name: "markets-spot-marketId",
    path: "spot/:marketId()",
    component: () => import("/root/injective/injective-explorer/pages/markets/spot/[marketId].vue")
  },
  {
    name: "markets-trading-rules",
    path: "trading-rules",
    component: () => import("/root/injective/injective-explorer/pages/markets/trading-rules.vue")
  },
  {
    name: "markets-futures-marketId",
    path: "/futures/:marketId()",
    component: () => import("/root/injective/injective-explorer/pages/markets/spot/[marketId].vue")
  },
  {
    name: "markets-perpetual-marketId",
    path: "/perpetual/:marketId()",
    component: () => import("/root/injective/injective-explorer/pages/markets/spot/[marketId].vue")
  },
  {
    name: "markets-binary-options-marketId",
    path: "/binary-options/:marketId()",
    component: () => import("/root/injective/injective-explorer/pages/markets/spot/[marketId].vue")
  }
]
  },
  {
    name: "raw-tx",
    path: "/raw-tx",
    component: () => import("/root/injective/injective-explorer/pages/raw-tx.vue")
  },
  {
    name: trade_45history4wdxWrri2GMeta?.name,
    path: "/trade-history",
    component: () => import("/root/injective/injective-explorer/pages/trade-history.vue"),
    children: [
  {
    name: "trade-history-derivatives-tradeId",
    path: "derivatives/:tradeId()",
    component: () => import("/root/injective/injective-explorer/pages/trade-history/derivatives/[tradeId].vue")
  },
  {
    name: "trade-history-derivatives",
    path: "derivatives",
    component: () => import("/root/injective/injective-explorer/pages/trade-history/derivatives/index.vue")
  },
  {
    name: "trade-history",
    path: "",
    meta: indexoVQXdDDGgUMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/trade-history/index.vue")
  },
  {
    name: "trade-history-spot-tradeId",
    path: "spot/:tradeId()",
    component: () => import("/root/injective/injective-explorer/pages/trade-history/spot/[tradeId].vue")
  },
  {
    name: "trade-history-spot",
    path: "spot",
    component: () => import("/root/injective/injective-explorer/pages/trade-history/spot/index.vue")
  }
]
  },
  {
    name: _91transaction_93O7jI16oisQMeta?.name,
    path: "/transaction/:transaction()",
    meta: _91transaction_93O7jI16oisQMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/transaction/[transaction].vue"),
    children: [
  {
    name: "transaction-transaction-event-logs",
    path: "event-logs",
    component: () => import("/root/injective/injective-explorer/pages/transaction/[transaction]/event-logs.vue")
  },
  {
    name: "transaction-transaction",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/transaction/[transaction]/index.vue")
  }
]
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/root/injective/injective-explorer/pages/transactions.vue")
  }
]